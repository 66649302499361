























import {Vue, Component, Prop} from 'vue-property-decorator';
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CreatePrefilledCompanyDocumentPayload from "@/dto/signature/CreatePrefilledCompanyDocumentPayload";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {OptionsBuilder} from "@/components/common/SelectOption";
import {SignableDocumentType} from "@/constants/SignatureConstants";
import SignatureService from "@/services/SignatureService";
import {namespace} from "vuex-class";
import {processError} from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  components: {PortalSelect, SingleFileHolder, SimpleCompanySearch}
})
export default class RequestSignatureForFilledDocumentModal extends Vue {

  @AppModule.Action private startLoading!: () => void
  @AppModule.Action private stopLoading!: () => void

  @Prop()
  onCreated!: () => void

  payload = new CreatePrefilledCompanyDocumentPayload();

  companySelected(c: CompanyDTO) {
    this.payload.companyId = c.id
  }

  getDocumentTypes() {
    return OptionsBuilder
        .of("Form TR-2000 revision 2021", SignableDocumentType.FORM_TR_2000_2021)
        .of("Form 8655 revision 2024", SignableDocumentType.FORM_8655_2024)
        .of("Form DOS-1556-F revision 2022", SignableDocumentType.FORM_DOS_1556_F_2022)
        .of("Form TR-579-CT revision 2023", SignableDocumentType.TR_579_CT_2023)
        .of("Form NYC-579-COR revision 2023", SignableDocumentType.NYC_579_COR_2023)
        .of("Form NYC-579-UBTP revision 2023", SignableDocumentType.NYC_579_UBTP_2023)
        .of("Form 8879-C", SignableDocumentType.FORM_8879_C)
        .of("Form 8879-CRP", SignableDocumentType.FORM_8879_CRP)
        .of("Form PA-8879-C revision 2022", SignableDocumentType.FORM_PA_8879_C_2022)
        .of("Form PA-8879-P revision 2023", SignableDocumentType.FORM_PA_8879_P_2023)
        .of("Form VA-8879-С revision 2023", SignableDocumentType.FORM_VA_8879_C_2023)
        .of("Form CBT-100 NJ", SignableDocumentType.FORM_CBT_100_NJ)
        .of("Form 1100 DELAWARE revision 2023", SignableDocumentType.FORM_1100_DELAWARE_2023)
        .of("Form IT-204-LL revision 2023", SignableDocumentType.FORM_IT_204_LL_2023)
        .of("Form SC-8453-C revision 2021", SignableDocumentType.FORM_SC_8453_C_2021)
        .of("Form CT-1065/CT-1120", SignableDocumentType.FORM_CT_1065_1120_CT)
        .of("Form 3115 revision 2022", SignableDocumentType.F_3115_2022)
        .of("Form 1120-A revision 2024", SignableDocumentType.FORM_1120A_2024)
        .get()
  }

  save() {
    this.startLoading();
    SignatureService.createPrefilledCompanyDocument(this.payload).then(
        ok => {
          this.$modal.hideAll();
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

}
